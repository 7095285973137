import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import api from "utils/axios";
export default function useFetchTickets() {
    const { agenceID } = useParams();
    const { tiquetID } = useParams();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1); // Track current page number
    const [tickets, setTickets] = useState([]);
    const [ticket, setTicket] = useState({});
    const [loading, setLoading] = useState(false);
    const [nameTicket, setNameTicket] = useState([]);
    const [agenceName, setAgenceName] = useState("");

    const [filterNumero, setFilterNumero]= useState("");
    const [filterTitre, setFilterTitre] = useState("");
    const [filterResponsable, setFilterResponsable]= useState("");
    const [filterEtat, setFilterEtat]=  useState("");
    const [filterDepartement, setFilterDepartement]= useState("");
    const [filterService, setFilterService] = useState("");
    const [filterDateDebut, setFilterDateDebut]= useState("");
    const [filterDateFin, setFilterDateFin] = useState("");
    const [filteredTickets, setFilteredTickets] = useState([]);

    const [departmentsByAgence, setDepartmentsByAgence]= useState([])
    const getDepartmentsByAgence = (agenceID) => {
        const endpoint = `tiquets/get-departments-by-agence/${agenceID}`;

        api.get(endpoint).then((response) => {
            setDepartmentsByAgence(response.data.departmentname);
        });
    };
    
    const [servicesByAgence, setServicesByAgence]= useState([])
    const getServicesByAgence = (agenceID) => {
        const endpoint = `tiquets/get-services-by-agence/${agenceID}`;

        api.get(endpoint).then((response) => {
            setServicesByAgence(response.data.servicename);
        });
    };

    useEffect(() => {
        if(agenceID){
            getDepartmentsByAgence(agenceID);
            getServicesByAgence(agenceID);
        }
    }, [agenceID]);


    useEffect(() => {
        if (tickets.length === 0) return;
      
        const parseDateString = (dateString) => {
            if (dateString !== undefined && dateString.trim()) {
                const [datePart, timePart] = dateString.split(' ');
                if (datePart && timePart) {
                    const [day, month, year] = datePart.split('/');
                    const [hours, minutes, seconds] = timePart.split(':');
                    return new Date(year, month - 1, day, hours, minutes, seconds);
                }
            }
            return null; // Return null if the string is malformed or undefined
        };
      
        const filterTicketList = () => {
          return tickets.filter((ticket) => {
            const matchesNumero = filterNumero ? ticket.TiquetNum.includes(filterNumero) : true;
            const matchesTitre = filterTitre ? ticket.title.includes(filterTitre) : true;
            const matchesResponsable = filterResponsable && filterResponsable !== "Default" ? ticket.responsable.includes(filterResponsable) : true;
            const matchesEtat = filterEtat && filterEtat !== "Default" ? ticket.etat.includes(filterEtat) : true;
            const matchesDepartement = filterDepartement && filterDepartement !== "Default" ? ticket.departments.some(department => department === filterDepartement) : true;
            const matchesService = filterService && filterService !== "Default" ? ticket.services.some(service => service === filterService) : true;
            
            const startDate = filterDateDebut ? new Date(filterDateDebut) : new Date(-8640000000000000);
            const endDate = filterDateFin ? new Date(filterDateFin) : new Date(8640000000000000);
            const createdAtDate = new Date(ticket.createdAt);
            const dateLimiteDate = parseDateString(ticket.dateLimite);

            const matchesDateRange = (
                (createdAtDate >= startDate && createdAtDate <= endDate) || 
                (dateLimiteDate !== null && dateLimiteDate >= startDate && dateLimiteDate <= endDate)
            );
            
            return matchesNumero && matchesTitre && matchesResponsable && matchesEtat && matchesDepartement && matchesService && matchesDateRange;
          });
        };
      
        const filteredList = filterTicketList();
      
        setFilteredTickets(filteredList);
      }, [filterNumero, filterTitre, filterResponsable, filterEtat, filterService, filterDepartement, filterDateDebut, filterDateFin, tickets]);
      
    const [emailUsers, setEmailUsers] = useState([]);
    const getEmailUsers = () => {
        const endpoint = "tiquets/getemail";

        api.get(endpoint).then((response) => {
            const observateursEmail = response.data.map((observateur) => observateur);
            setEmailUsers(observateursEmail);
        });
    };

    useEffect(() => {
        getEmailUsers();
    }, [setEmailUsers]);


    const handleResponsablesImageAndNameByEmail = async (emailResponsables) => {
        const endpoint = `tiquets/get-responsable-image-and-name?emails=${emailResponsables}`;
        try {
          const response = await api.get(endpoint);
          return response.data
        //   return `${process.env.REACT_APP_BASE_URL}uploads/users/${response.data.imageFilename}`;
        } catch (error) {
          console.error("Failed to fetch image", error);
          return ""; // Return an empty string or a placeholder image URL on failure
        }
    };
    
    const handleTicket = async (tiquetID) =>{
        const endpoint = `tiquets/getTiquet/${tiquetID}`;
        const response = await api.get(endpoint)
        setTicket(response.data);
    }

    useEffect(()=>{
        if(tiquetID){
            handleTicket(tiquetID);
        }
    }, [tiquetID]);
    
    const listTicketsByAgence = async () => {
        setLoading(true);
        try {
            const endpoint = `tiquets/getTiquetByAgency/${agenceID}`;
            const response = await api.get(endpoint);
            setTickets(response.data);
        } catch (error) {
            console.error("Error fetching tickets:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(agenceID){
            listTicketsByAgence();
        }
    }, [agenceID]);

    const ListAgences = async () => {
        try {
            const endpoint = "agencies";
            const response = await api.get(endpoint);
            const agencies = response.data.data;
            const agencyOptions = agencies.map(agence => ({
                value: agence._id,
                label: agence.name,
            }));
            setNameTicket(agencyOptions);
        } catch (error) {
            console.error('Error fetching Agences names:', error);
        }
    };

    useEffect(() => {
        document.title = "Reclamation";
        ListAgences();

        if(!agenceID){
            localStorage.removeItem("agenceName");
        }
    }, []);

    useEffect(() => {
        const storedAgencyName = localStorage.getItem('agenceName');
        const storedAgencyID = localStorage.getItem('agenceID');
        if (storedAgencyName && storedAgencyID) {
            setAgenceName(storedAgencyName);
        }
    }, [navigate]);
    
    const handleAgenceChange = (e, navig) => {
        setAgenceName(e.label);
        localStorage.setItem('agenceName', e.label);
        localStorage.setItem('agenceID', e.value);
        navigate(`/${navig}/${e.value}`);
    };

    useEffect(() => {
        if (agenceName) {
            localStorage.setItem('agenceName', agenceName);
        }
    }, [agenceName]);

    const deleteTicket = async (id) => {
        try {
            const endpoint = `tiquets/deleteTiquet/${id}`;
            await api.delete(endpoint);
            await listTicketsByAgence();
            Swal.fire({
                title: "Succès",
                text: "Suppression de ticket réussie",
                icon: "success",
                confirmButtonText: "OK",
                customClass: {
                    confirmButton: "button button-success",
                },
                buttonsStyling: false,
            });
        } catch (error) {
            Swal.fire({
                title: "Erreur",
                text: "Erreur lors de la suppression de ticket",
                icon: "error",
                confirmButtonText: "OK",
                customClass: {
                    confirmButton: "button button-error",
                },
                buttonsStyling: false,
            });
        }
    };

    const confirmDeleteTicket = (id) => {
        Swal.fire({
            title: "Êtes-vous sûr(e)",
            text: "Vous ne pourrez pas revenir en arrière !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui, supprimer !",
            cancelButtonText: "Annuler",
            customClass: {
                confirmButton: "button button-success",
                cancelButton: "button button-error",
            },
            buttonsStyling: false,
        }).then((result) => {
            if (result.isConfirmed) {
                deleteTicket(id);
            }
        });
    };

    return {
        agenceID, navigate,
        currentPage, tickets, setTickets,
        loading, nameTicket,
        agenceName, setAgenceName,
        handleAgenceChange,
        confirmDeleteTicket,
        handleResponsablesImageAndNameByEmail,
        ticket,
        emailUsers,
        setFilterNumero,
        setFilterTitre,
        filterResponsable, setFilterResponsable,
        filterEtat, setFilterEtat,
        filterDepartement, setFilterDepartement,
        filterService, setFilterService,
        setFilterDateDebut,
        setFilterDateFin,
        filteredTickets, setFilteredTickets,
        departmentsByAgence,
        servicesByAgence
    }

}