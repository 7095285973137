/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";

// DefaultNavbar dropdown menus
import PagesMenu from "examples/Navbars/DefaultNavbar/Menus/PagesMenu";
import AuthenticationMenu from "examples/Navbars/DefaultNavbar/Menus/AuthenticationMenu";
import ApplicationsMenu from "examples/Navbars/DefaultNavbar/Menus/ApplicationsMenu";
import EcommerceMenu from "examples/Navbars/DefaultNavbar/Menus/EcommerceMenu";
import DocsMenu from "examples/Navbars/DefaultNavbar/Menus/DocsMenu";

function DefaultNavbarMobile({ routes, open, close }) {
  const width = open?.getBoundingClientRect()?.width ?? 0;
  const [openCollapse, setOpenCollapse] = useState('');

  const handleSepOpenCollapse = (name) =>
    openCollapse === name ? setOpenCollapse('') : setOpenCollapse(name);

  return (
    <Menu
    id="Menu1-DefaultNavbarMobile"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      <SoftBox  id="SoftBox1-DefaultNavbarMobile" px={0.5}>
        {['pages', 'authentication', 'applications', 'ecommerce', 'docs'].map((section) => (
          <DefaultNavbarLink
           id="Menu1-DefaultNavbarMobile"
            key={section}
            name={section}
            collapseStatus={openCollapse === section}
            onClick={() => handleSepOpenCollapse(section)}
          >
            <SoftBox  id="SoftBox2-DefaultNavbarMobile" maxHeight="16rem" overflow="auto">
              {section === 'pages' && <PagesMenu id='PagesMenu' routes={routes} mobileMenu />}
              {section === 'authentication' && <AuthenticationMenu id='AuthenticationMenu' routes={routes} mobileMenu />}
              {section === 'applications' && <ApplicationsMenu id='ApplicationsMenu' routes={routes} mobileMenu />}
              {section === 'ecommerce' && <EcommerceMenu id='EcommerceMenu' routes={routes} mobileMenu />}
              {section === 'docs' && <DocsMenu id='DocsMenu' routes={routes} mobileMenu />}
            </SoftBox>
          </DefaultNavbarLink>
        ))}
      </SoftBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMobile
DefaultNavbarMobile.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.func.isRequired,
};

export default DefaultNavbarMobile;
