import React from 'react';
import { Card, CardContent, Grid, CircularProgress, Slide, Divider, Button, Stack } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import useFetchStatut from './hooks/useFetchStatut';
import DataTable from 'examples/Tables/DataTable';
import StatutActions from './components/StatutActions';
import AddStatut from './AddStatut';
import SoftButton from 'components/SoftButton';

function StatutTable() {
  const {
    loading,
    data,
    myRef,
    handleFetch,
  } = useFetchStatut();

  return (
    <SoftBox  id="statuts-container-box" my={3}>
      <Card id="statuts-card">
      <SoftBox
        id="statuts-header-box"
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          p={3}
        >
          <SoftBox id="statuts-header-title-box" lineHeight={1}>
            <SoftTypography  id="statuts-title" variant="h5" fontWeight="medium">
              Liste des statuts
            </SoftTypography>
          </SoftBox>
          <Stack id="statuts-actions-stack" spacing={1} direction={{ xs: 'column', sm: 'row' }}>
            <AddStatut id="add-statut-button" handleFetch={handleFetch} />
          </Stack>
        </SoftBox>
        
        {loading ? (
          <SoftBox   id="statuts-loading-box" sx={{ mt: 13, textAlign: 'center' }}>
            <CircularProgress id="statuts-loading-spinner"  />
          </SoftBox>
        ) : (
          <div id="statuts-content-div">
            <Slide  id="statuts-slide" direction="left" in={true} mountOnEnter unmountOnExit>
              <div id="statuts-slide-content" ref={myRef}>
                
                  <DataTable
                    id="statuts-data-table"
                    table={{
                      columns: [
                        { Header: "Nom de statut", accessor: 'titre' },
                        { Header: "action", accessor: "action", width: "12%" },
                      ],
                      rows: data?.map((statut) => ({
                        titre: statut?.titre,
                        action: (
                          <StatutActions
                          id={`statut-actions-${statut._id}`}
                            key={statut?._id}
                            statut={statut}
                            handleFetch={handleFetch}
                            
                          />
                        ),
                      })),
                    }}
                    entriesPerPage={{
                      defaultValue: 7,
                      entries: [5, 7, 10, 15, 20, 25],
                    }}
                    canSearch
                  />
              
              </div>
            </Slide>
           
          </div>
        )}
      </Card>
    </SoftBox>
  );
}

export default StatutTable;
