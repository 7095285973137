
import { useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// react-router components
import { Link } from "react-router-dom";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DefaultNavbarMenu from "examples/Navbars/DefaultNavbar/DefaultNavbarMenu";

// Images
import curved8 from "assets/images/curved-images/curved8.jpg";

function Menu({ collapse, name, mobileMenu }) {
  const [menu, setMenu] = useState(false);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(false);

  return (
    <MenuItem id='MenuItem1' onMouseEnter={openMenu} onMouseLeave={closeMenu}>
      {name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Icon id='icon1' sx={{ fontWeight: "bold", ml: "auto" }}>chevron_right</Icon>
      <DefaultNavbarMenu
      id='DefaultNavbarMenu'
        placement="right-start"
        open={menu}
        close={closeMenu}
        style={{ paddingLeft: "1.25rem" }}
      >
        {collapse.map(({ key: collapseKey, name: collapseName, route }) => (
          <MenuItem
          id='MenuItem2'
            component={Link}
            to={route}
            key={collapseKey}
            onClick={mobileMenu ? undefined : close}
          >
            {collapseName}
          </MenuItem>
        ))}
      </DefaultNavbarMenu>
    </MenuItem>
  );
}
// PropTypes validation
Menu.propTypes = {
  collapse: PropTypes.bool,
  name: PropTypes.string.isRequired,
  mobileMenu: PropTypes.bool,
};
function AuthenticationMenu({ routes, open, close, mobileMenu }) {
  const renderAuthenticationMenuRoute = (routeName) =>
    routes.map(({ key, name, collapse }) => {
      let template;

      if (key === routeName && !mobileMenu) {
        template = <Menu id='Menu' collapse={collapse} mobileMenu={mobileMenu} name={name} />;
      } else if (key === routeName && mobileMenu) {
        template = (
          <SoftBox id='SoftBox1' key={key} pr={2} mt={0} mb={2}>
            <SoftTypography id='SoftTypography1' variant="h6" fontWeight="bold" gutterBottom>
              {name}
            </SoftTypography>
            {collapse.map(({ key: collapseKey, name: collapseName, route }) => (
              <MenuItem
              id='MenuItem3'
                component={Link}
                to={route}
                key={collapseKey}
                onClick={mobileMenu ? undefined : close}
              >
                {collapseName}
              </MenuItem>
            ))}
          </SoftBox>
        );
      }

      return template;
    });

  const renderMenuContent = (
    <SoftBox id='SoftBox' display={mobileMenu ? "block" : "flex"}>
      {!mobileMenu && (
        <SoftBox
        id='SoftBox2'
          width="10rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          borderRadius="lg"
          overflow="hidden"
        >
          <SoftBox
          id='SoftBox3'
            component="img"
            src={curved8}
            alt="background-image"
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
          />
          <SoftBox
          id='SoftBox4'
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            opacity={0.8}
            bgColor="info"
            variant="gradient"
          />
          <SoftBox id='SoftBox5' position="relative" textAlign="center">
            <SoftBox
            id='SoftBox6'
              bgColor="white"
              width="3rem"
              height="3rem"
              borderRadius="50%"
              shadow="md"
              display="flex"
              justifyContent="center"
              alignItems="center"
              mx="auto"
              mb={1}
            >
              <Icon
              id='icon2'
                sx={({ functions: { linearGradient }, palette: { gradients, transparent } }) => ({
                  backgroundImage: `${linearGradient(
                    gradients.info.main,
                    gradients.info.state
                  )} !important`,
                  WebkitBackgroundClip: "text !important",
                  WebkitTextFillColor: `${transparent.main} !important`,
                })}
              >
                star
              </Icon>
            </SoftBox>
            <SoftTypography id='SoftTypography2' variant="body1" fontWeight="medium" color="white">
              Explore our utilities pages
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      )}
      <SoftBox id='SoftBox7' py={1} pl={2}>
        {renderAuthenticationMenuRoute("sign-in")}
        {renderAuthenticationMenuRoute("sign-up")}
        {renderAuthenticationMenuRoute("reset-password")}
        {renderAuthenticationMenuRoute("lock")}
        {renderAuthenticationMenuRoute("2-step-verification")}
        {renderAuthenticationMenuRoute("error")}
      </SoftBox>
    </SoftBox>
  );

  return mobileMenu ? (
    renderMenuContent
  ) : (
    <DefaultNavbarMenu id='DefaultNavbarMenu' open={open} close={close}>
      {renderMenuContent}
    </DefaultNavbarMenu>
  );
}

// Setting default values for the props of AuthenticationMenu
AuthenticationMenu.defaultProps = {
  mobileMenu: false,
  open: false,
  close: false,
};

// Typechecking props for the AuthenticationMenu
AuthenticationMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  close: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  mobileMenu: PropTypes.bool,
};

export default AuthenticationMenu;
