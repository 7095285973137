import { createContext, useContext, useEffect, useMemo, useState } from "react";
import io from "socket.io-client";
import { useSoftUIController, setConnectedUsers, setNotification } from "context";
import PropTypes from "prop-types";
import { Slide, Snackbar } from "@mui/material";
// Create a new context for the socket
const SocketContext = createContext();

// Custom hook to use the SocketContext
export const useSocket = () => useContext(SocketContext);

// Transition component for Snackbar
const TransitionLeft = (props) => <Slide {...props} direction="right" />;

export const SocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [controller, dispatch] = useSoftUIController();
  const [alert, setAlert] = useState(false);
  const [messageText, setMessageText] = useState("");
  const user = controller?.user?.info?.user;
  const token = localStorage.getItem('accessToken');

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({ socket }), [socket]);

  useEffect(() => {
    if (!user) return;

    // Initialize socket with user id
    const socketInstance = io(process.env.REACT_APP_SOCKET_URL, {
      query: { userId: user._id, token: token },
    });
    

    // Set socket state
    setSocket(socketInstance);

    // Emit various events
    socketInstance.emit("connected", (data) => setConnectedUsers(dispatch, data));
    socketInstance.emit("join", "hello");
    socketInstance.emit("setup", user);
    socketInstance.emit("newConnection", user._id,token);

    // Listen for events
    const handleConnectedUsers = (data) => setConnectedUsers(dispatch, data);
    

    socketInstance.on("connectedUsers", handleConnectedUsers);
    

    // Cleanup on unmount
    return () => {
      socketInstance.off("connectedUsers", handleConnectedUsers);
      socketInstance.close();
    };
  }, [user,token]);

  return (
    <SocketContext.Provider value={contextValue} id='SocketContext-Provider'>
      {children}
      <Snackbar
       id='Snackbar-Provider'
      />
    </SocketContext.Provider>
  );
};

SocketContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
