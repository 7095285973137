import { Box, Card, IconButton, Tooltip, CircularProgress, Stack, Typography, Grid } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import DataTable from "examples/Tables/DataTable";
import { green, grey, red } from "@mui/material/colors";
import {
  Delete,
  DragHandle,
  Edit,
  KeyboardArrowDown,
  KeyboardDoubleArrowUp,
  Visibility,
} from "@mui/icons-material";
import PropTypes from 'prop-types';
import useFetchTickets from "../hooks/useFetchTickets";
import moment from "moment";
import SoftInput from "components/SoftInput";
import styled from "@emotion/styled";
import SelectInfo from "../data/SelectInfo";

// Create a styled component for SoftSelect
const CustomSoftSelect = styled(SoftSelect)(({ theme }) => ({
  '& .css-l4kyc0-Control': {
    backgroundColor: '#ffffff !important',
    // Ensure to handle the states directly
    '&:hover': {
      backgroundColor: '#ffffff !important',
    },
    '&.css-l4kyc0-Control--isFocused': {
      backgroundColor: '#ffffff !important',
      borderColor: '#a0a0a0 !important',
    },
    '&.css-l4kyc0-Control--isSelected': {
      backgroundColor: '#ffffff !important',
    },
  },

}));

function HistoriqueTickets() {
  const {
    agenceID, navigate,
    currentPage, tickets,
    loading, nameTicket,
    agenceName, setAgenceName,
    handleAgenceChange,
    confirmDeleteTicket,
    responsableImage,
    responsableName,
    emailUsers,
    setFilterNumero,
    setFilterTitre,
    filterResponsable, setFilterResponsable,
    filterEtat, setFilterEtat,
    filterDepartement, setFilterDepartement,
    filterService, setFilterService,
    setFilterDateDebut,
    setFilterDateFin,
    filteredTickets, setFilteredTickets,
    departmentsByAgence,
    servicesByAgence
  } = useFetchTickets()

  const fourDaysAgo = moment().subtract(4, 'days');

  // Filter tickets based on the threshold date
  const filteredAgain = filteredTickets.filter(ticket => {
    const dateLimite = moment(ticket.dateLimite, "DD/MM/YYYY HH:mm:ss");

    // Only show tickets if the ticket is "Terminer" and dateLimite is before 4 days ago
    return ticket.etat === "Terminer" && dateLimite.isBefore(fourDaysAgo);
  });

  return (
    <>
    {/* Agences Selection */}
    <Grid container id="agences-selection-container">
      <Grid item xs={12} md={3} mt="12px" id="agences-selection-grid-item">
        <SoftTypography
          id="agences-selection-title"
          variant="h5"
          sx={{ fontSize: "18px" }}
        >
          Liste Des Agences
        </SoftTypography>
        <SoftSelect
          id="agences-select"
          placeholder="Choissiser un agence"
          options={nameTicket}
          size="medium"
          value={agenceName ? { label: agenceName, value: agenceID } : null}
          onChange={(e) => {
            handleAgenceChange(e, "historique/listTickets");
          }}
        />
      </Grid>
    </Grid>
  
    {/* Filter Section */}
    <SoftTypography id="filter-section-title" variant="h5" sx={{ mt: "16px" }}>
      Filter
    </SoftTypography>
    <Grid
      container
      id="filter-section-grid"
      mt="12px"
      spacing={1}
      sx={{ display: "flex", alignItems: "center" }}
    >
      {/* Numero Filter */}
      <Grid item xs={6} md={3} id="filter-numero-grid-item">
        <SoftTypography
          id="filter-numero-label"
          variant="h5"
          sx={{ fontSize: "12px" }}
        >
          Numero
        </SoftTypography>
        <SoftInput
          id="filter-numero-input"
          type="text"
          placeholder="Numero de Ticket"
          onChange={(e) => {
            setFilterNumero(e.target.value);
          }}
          sx={{
            "& .MuiInputBase-root": {
              bgcolor: "grey !important", // Set the background color
            },
            "& .css-1avhgs1-MuiInputBase-root": {
              bgcolor: "red !important",
            },
          }}
        />
      </Grid>
  
      {/* Titre Filter */}
      <Grid item xs={6} md={3} id="filter-titre-grid-item">
        <SoftTypography
          id="filter-titre-label"
          variant="h5"
          sx={{ fontSize: "12px" }}
        >
          Titre
        </SoftTypography>
        <SoftInput
          id="filter-titre-input"
          placeholder="Titre de Ticket"
          variant="outlined"
          onChange={(event) => {
            setFilterTitre(event.target.value);
          }}
          sx={{
            "& .MuiInputBase-root": {
              bgcolor: "grey !important", // Set the background color
            },
            "& .css-1avhgs1-MuiInputBase-root": {
              bgcolor: "red !important",
            },
          }}
        />
      </Grid>
  
      {/* Departement Filter */}
      <Grid item xs={6} md={3} id="filter-departement-grid-item">
        <SoftTypography
          id="filter-departement-label"
          variant="h5"
          sx={{ fontSize: "12px" }}
        >
          Departement
        </SoftTypography>
        <CustomSoftSelect
          id="filter-departement-select"
          options={[
            { value: "Default", label: "Default" },
            ...departmentsByAgence.map((departement, index) => ({
              value: departement,
              label: departement,
              id: `departement-option-${index}`,
            })),
          ]}
          value={
            filterDepartement !== "" && filterDepartement !== "Default"
              ? { label: filterDepartement, value: filterDepartement }
              : null
          }
          placeholder="Departement de Ticket"
          onChange={(e) => {
            setFilterDepartement(e.value);
          }}
        />
      </Grid>
  
      {/* Service Filter */}
      <Grid item xs={6} md={3} id="filter-service-grid-item">
        <SoftTypography
          id="filter-service-label"
          variant="h5"
          sx={{ fontSize: "12px" }}
        >
          Service
        </SoftTypography>
        <CustomSoftSelect
          id="filter-service-select"
          options={[
            { value: "Default", label: "Default" },
            ...servicesByAgence.map((service, index) => ({
              value: service,
              label: service,
              id: `service-option-${index}`,
            })),
          ]}
          value={
            filterService !== "" && filterService !== "Default"
              ? { label: filterService, value: filterService }
              : null
          }
          placeholder="Service de ticket"
          onChange={(e) => {
            setFilterService(e.value);
          }}
        />
      </Grid>
  
      {/* Responsable Filter */}
      <Grid item xs={12} md={6} id="filter-responsable-grid-item">
        <SoftTypography
          id="filter-responsable-label"
          variant="h5"
          sx={{ fontSize: "12px" }}
        >
          Responsable
        </SoftTypography>
        <CustomSoftSelect
          id="filter-responsable-select"
          options={[
            { value: "Default", label: "Default" },
            ...emailUsers.map((emailUser, index) => ({
              value: emailUser,
              label: emailUser,
              id: `responsable-option-${index}`,
            })),
          ]}
          value={
            filterResponsable !== "" && filterResponsable !== "Default"
              ? { label: filterResponsable, value: filterResponsable }
              : null
          }
          placeholder="Responsable de Ticket"
          onChange={(e) => {
            setFilterResponsable(e.value);
          }}
        />
      </Grid>
  
      {/* Date Range Filter */}
      <Grid
        item
        xs={12}
        md={6}
        spacing={1}
        id="filter-date-range-grid-item"
      >
        <SoftTypography
          id="filter-date-range-label"
          variant="h5"
          sx={{ fontSize: "12px" }}
        >
          {"Date Range : Debut -----> Fin"}
        </SoftTypography>
        <Box
          display="flex"
          alignItems="center"
          id="filter-date-range-box"
        >
          <Grid
            item
            xs={6}
            mr="4px"
            id="filter-date-debut-grid-item"
          >
            <SoftInput
              id="filter-date-debut-input"
              type="date"
              placeholder="date début"
              onChange={(e) => {
                setFilterDateDebut(e.target.value);
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            ml="4px"
            id="filter-date-fin-grid-item"
          >
            <SoftInput
              id="filter-date-fin-input"
              type="date"
              placeholder="date fin"
              onChange={(e) => {
                setFilterDateFin(e.target.value);
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  
    {/* Historique des Tickets Header */}
    <Card id="historique-tickets-card" sx={{ my: 2 }}>
      {loading ? (
        <Box
          id="historique-tickets-loading-box"
          sx={{ mt: 13, textAlign: "center" }}
        >
          <CircularProgress id="historique-tickets-loading-spinner" />
        </Box>
      ) : (
        <Box id="historique-tickets-content-box">
          <Box
            id="historique-tickets-header-box"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={{ xs: "flex-start", sm: "space-between" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            p={3}
          >
            <SoftTypography id="historique-tickets-title">
              Historique des tickets
            </SoftTypography>
          </Box>
  
          {/* DataTable */}
          <DataTable
            id="historique-tickets-data-table"
            table={{
              columns: [
                {
                  Header: "Num",
                  accessor: "num",
                  width: "5%",
                  id: "num-column",
                },
                {
                  Header: "Titre",
                  accessor: "nom",
                  width: "10%",
                  id: "titre-column",
                },
                {
                  Header: "Responsable",
                  accessor: "responsable",
                  width: "15%",
                  id: "responsable-column",
                },
                {
                  Header: "Priorité",
                  accessor: "priorite",
                  width: "10%",
                  id: "priorite-column",
                },
                {
                  Header: "Statut",
                  accessor: "statut",
                  width: "10%",
                  id: "statut-column",
                },
                {
                  Header: "Etat",
                  accessor: "etat",
                  width: "10%",
                  id: "etat-column",
                },
                {
                  Header: "Date Limite",
                  accessor: "dateLimite",
                  width: "15%",
                  id: "date-limite-column",
                },
                {
                  Header: "Action",
                  accessor: "action",
                  id: "action-column",
                },
              ],
              rows: filteredAgain
                .slice(
                  (currentPage - 1) * filteredAgain.length,
                  currentPage * filteredAgain.length
                )
                .map((ticket, index) => ({
                  num: ticket.TiquetNum,
                  nom:
                    ticket.title?.length > 20 ? (
                      <Tooltip
                        key={`tooltip-titre-${ticket._id}`}
                        title={ticket.title}
                      >
                        <span id={`ticket-title-${ticket._id}`}>
                          {ticket.title.substr(0, 20) + "..."}
                        </span>
                      </Tooltip>
                    ) : (
                      <span id={`ticket-title-${ticket._id}`}>
                        {ticket.title}
                      </span>
                    ),
                  responsable:
                    ticket.responsable?.length > 24 ? (
                      <Tooltip
                        key={`tooltip-responsable-${ticket._id}`}
                        title={ticket.responsable}
                      >
                        <span id={`ticket-responsable-${ticket._id}`}>
                          {ticket.responsable.substr(0, 20) + "..."}
                        </span>
                      </Tooltip>
                    ) : (
                      <span id={`ticket-responsable-${ticket._id}`}>
                        {ticket.responsable}
                      </span>
                    ),
                  priorite: (
                    <Box
                      id={`ticket-priorite-box-${ticket._id}`}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {ticket.priorite === "Haute" ? (
                        <KeyboardDoubleArrowUp
                          id={`priorite-icon-haute-${ticket._id}`}
                          color="error"
                        />
                      ) : ticket.priorite === "Moyenne" ? (
                        <DragHandle
                          id={`priorite-icon-moyenne-${ticket._id}`}
                          color="warning"
                        />
                      ) : (
                        <KeyboardArrowDown
                          id={`priorite-icon-basse-${ticket._id}`}
                          color="info"
                        />
                      )}
                      <Typography
                        id={`priorite-text-${ticket._id}`}
                        variant="body"
                        sx={{ ml: "8px" }}
                      >
                        {ticket.priorite}
                      </Typography>
                    </Box>
                  ),
                  statut:
                    ticket.statut > 20 ? (
                      <span id={`ticket-statut-${ticket._id}`}>
                        {ticket.statut.substr(0, 20)}
                      </span>
                    ) : (
                      <span id={`ticket-statut-${ticket._id}`}>
                        {ticket.statut}
                      </span>
                    ),
                  etat: (
                    <span id={`ticket-etat-${ticket._id}`}>{ticket.etat}</span>
                  ),
                  dateLimite: (
                    <Tooltip
                      key={`tooltip-dateLimite-${ticket._id}`}
                      title={ticket.dateLimite}
                    >
                      <span id={`ticket-dateLimite-${ticket._id}`}>
                        {moment(ticket.dateLimite, "DD/MM/YYYY HH:mm:ss").format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    </Tooltip>
                  ),
                  action: (
                    <Box
                      id={`ticket-action-box-${ticket._id}`}
                      display="flex"
                      alignItems="center"
                    >
                      <Tooltip
                        key={`tooltip-voir-plus-${ticket._id}`}
                        title="Voir Plus"
                      >
                        <IconButton
                          id={`voir-plus-button-${ticket._id}`}
                          sx={{ "&:hover": { bgcolor: grey[200] } }}
                          onClick={() => {
                            navigate(`/reclamation/voirTicket/${ticket._id}`);
                          }}
                        >
                          <Visibility
                            id={`visibility-icon-${ticket._id}`}
                            fontSize="small"
                            color="purple"
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ),
                })),
            }}
            entriesPerPage={{ defaultValue: 7, entries: [5, 7, 10, 15, 20, 25] }}
            canSearch
          />
        </Box>
      )}
    </Card>
  </>
  
  );
}
HistoriqueTickets.propTypes = {
  id: PropTypes.string.isRequired,
};
export default HistoriqueTickets; 
