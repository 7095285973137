import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React components
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useTheme } from "@emotion/react";
import { useState } from "react";
import { Box } from "@mui/material";
import SoftSelect from "components/SoftSelect";


function MiniStatisticsCard({ bgColor, title, count, percentage, isCharge, date, icon, direction, onChangeDate, namesAgence , agenceNameChoisi , setAgenceNameChoisi, agenceID, setAgenceID, title2, count2, title3, count3, titres, ticketNameChoisi, setTicketNameChoisi, idTicketsRepeter }) {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (newValue) => {
    onChangeDate(newValue); // Call the onChangeDate callback with the new value
    handleClose();
  };

  return (
    <Card id='card1' sx={{ overflow:"visible", zIndex:0 }}>
      <SoftBox id='SoftBox1'  bgColor={bgColor} variant="gradient">
        <SoftBox id='SoftBox2' p={3}>
          <Grid id='Grid1' container alignItems="center">
            <Box id='box1' display="flex" alignItems="center"> 
              {
                namesAgence &&
                  <Grid id='Grid2' item xs={12} sx={{ display:"block", mb:"12px", mr:"16px" }}>
                    <SoftTypography id='SoftTyp1' variant="body" color="text" sx={{ fontSize: "12px", pr: "12px" }} >
                      Nom Agence
                    </SoftTypography>
                    <SoftSelect
                    id='SoftSelect1'
                      placeholder="Choissiser un agence"
                      options={namesAgence}
                      size="small"
                      value={agenceNameChoisi ?{ label: agenceNameChoisi, value: agenceID }: null}
                      onChange={(e)=>{
                        setAgenceNameChoisi(e.label)
                        setAgenceID(e.value)
                      }}
                    />
                  </Grid>
              }
              {
                titres &&
                  <Grid id='Grid3' item xs={12} sx={{ display:"block", mb:"12px" }}>
                    <SoftTypography id='SoftTyp2' variant="body" color="text" sx={{ fontSize: "12px", pr: "12px" }} >
                      Nom Ticket
                    </SoftTypography>
                    <SoftSelect
                    id='SoftSelect2'
                      placeholder="Choissiser un agence"
                      options={titres.map((titre)=>{
                        return {
                          label: titre,
                          value: titre
                        }
                      })}
                      size="small"
                      value={ticketNameChoisi ?{ label: ticketNameChoisi, value: ticketNameChoisi }: null}
                      onChange={(e)=>{
                        setTicketNameChoisi(e.label)
                      }}
                    />
                  </Grid>
              }
            </Box>
            {direction === "left" ? (
              <Grid id='Grid4' item>
                <SoftBox
                id='SoftBox3'
                  variant="gradient"
                  bgColor={bgColor === "white" ? icon.color : "white"}
                  color={bgColor === "white" ? "white" : "dark"}
                  width="3rem"
                  height="3rem"
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  <Icon id='Icon1' fontSize="small" color="inherit">
                    {icon.component}
                  </Icon>
                </SoftBox>
              </Grid>
            ) : null}
            <Grid id='Grid5'item xs={8}>
              <SoftBox id='SoftBox4' ml={direction === "left" ? 2 : 0} lineHeight={1}>
                <Typography
                id='Typograth1'
                  variant="button"
                  color={bgColor === "white" ? "text" : "white"}
                  opacity={bgColor === "white" ? 1 : 0.7}
                  textTransform="capitalize"
                  fontWeight={title.fontWeight}
                  sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between"
                  }}
                >
                  <SoftTypography id='SoftTyp3' variant="button" color={"secondary"} fontWeight="bold">
                    {title.text}
                  </SoftTypography>
                  <Typography id='Typograth2' variant="button" color={theme.palette.text.primary} fontWeight="medium" sx={{ fontSize : "10px" , textTransform : "capitalize" , "&:hover":{cursor:"pointer"} }} onClick={handleClick}>
                    {date}
                  </Typography>
                </Typography>
                <Box id='Box2' display="flex">
                  <SoftTypography
                  id='SoftTyp4'
                    variant="h5"
                    fontWeight="bold"
                    color={bgColor === "white" ? "dark" : "white"}
                    sx={{ mr : "2px" }}
                  >
                    {count}
                  </SoftTypography>
                  {
                      percentage !== undefined && !isCharge && 
                        <SoftTypography   id='SoftTyp5'fontWeight="medium" fontSize="12px" color={percentage.charAt(0) === "-" ? "error" : "success"} sx={{mt : "8px"}}>{percentage}</SoftTypography>
                  }
                  {
                      percentage !== undefined && isCharge && 
                        <SoftTypography   id='SoftTyp6' fontWeight="medium" fontSize="12px" color={percentage.charAt(0) === "-" ? "success" : "error"} sx={{mt : "8px"}}>{percentage}</SoftTypography>
                  }
                </Box>
                {
                  titres && ticketNameChoisi &&
                    <SoftTypography
                      id='SoftTyp7'
                      variant="h5"
                      fontWeight="bold"
                      color={bgColor === "white" ? "dark" : "white"}
                      sx={{ whiteSpace: "wrap" }}
                    >
                      {idTicketsRepeter.join(", ")}
                    </SoftTypography>
                }
              </SoftBox>
              <SoftBox id='SoftBox5' ml={direction === "left" ? 2 : 0} lineHeight={1}>
                <Typography
                 id='SoftTyp5'
                  variant="button"
                  color={bgColor === "white" ? "text" : "white"}
                  opacity={bgColor === "white" ? 1 : 0.7}
                  textTransform="capitalize"
                  fontWeight={title.fontWeight}
                  sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between"
                  }}
                >
                  <SoftTypography   id='SoftTyp8'variant="button" color={"secondary"} fontWeight="bold">
                    {title2}
                  </SoftTypography>
                </Typography>
                <Box id='Box3' display="flex">
                  <SoftTypography
                    id='SoftTyp9'
                    variant="h5"
                    fontWeight="bold"
                    color={bgColor === "white" ? "dark" : "white"}
                    sx={{ mr : "2px" }}
                  >
                    {count2}
                  </SoftTypography>
                </Box>
              </SoftBox>
              <SoftBox id='SoftBox6' ml={direction === "left" ? 2 : 0} lineHeight={1}>
                <Typography
                 id='SoftTyp46'
                  variant="button"
                  color={bgColor === "white" ? "text" : "white"}
                  opacity={bgColor === "white" ? 1 : 0.7}
                  textTransform="capitalize"
                  fontWeight={title.fontWeight}
                  sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between"
                  }}
                >
                  <SoftTypography   id='SoftTyp10'variant="button" color={"secondary"} fontWeight="bold">
                    {title3}
                  </SoftTypography>
                </Typography>
                <Box id='Box4' display="flex">
                  <SoftTypography
                    id='SoftTyp11'
                    variant="h5"
                    fontWeight="bold"
                    color={bgColor === "white" ? "dark" : "white"}
                    sx={{ mr : "2px" }}
                  >
                    {count3}
                  </SoftTypography>
                </Box>
              </SoftBox>
            </Grid>
            {direction === "right" ? (
              <Grid id='Grid6' item xs={4}>
                <SoftBox
                id='SoftBox7'
                  variant="gradient"
                  bgColor={bgColor === "white" ? icon.color : "white"}
                  color={bgColor === "white" ? "white" : "dark"}
                  width="3rem"
                  height="3rem"
                  marginLeft="auto"
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  <Icon fontSize="small" color="inherit">
                    {icon.component}
                  </Icon>
                </SoftBox>
              </Grid>
            ) : null}
          </Grid>
        </SoftBox>
      </SoftBox>

      <Menu
      id='Menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem id='MenuI1' onClick={() => handleMenuItemClick("Année Actuelle")}>Année Actuelle</MenuItem>
        <MenuItem id='MenuI2' onClick={() => handleMenuItemClick("Mois Actuel")}>Mois Actuel</MenuItem>
        <MenuItem id='MenuI3' onClick={() => handleMenuItemClick("Jour Actuel")}>Jour Actuel</MenuItem>
      </Menu>
    </Card>
  );
}

// Setting default values for the props of MiniStatisticsCard
MiniStatisticsCard.defaultProps = {
  bgColor: "white",
  isCharge:false,
  title: {
    fontWeight: "medium",
    text: "",
  },
  direction: "right",
};

// Typechecking props for the MiniStatisticsCard
MiniStatisticsCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title2: PropTypes.string, 
  count2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title3: PropTypes.string, 
  count3: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage : PropTypes.string,
  isCharge: PropTypes.bool,
  date: PropTypes.string,
  onChangeDate: PropTypes.func,
  icon: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(["right", "left"]),

  namesAgence : PropTypes.arrayOf(PropTypes.string),
  agenceNameChoisi : PropTypes.string,
  setAgenceNameChoisi : PropTypes.func,
  agenceID : PropTypes.string,
  setAgenceID : PropTypes.func,

  titres : PropTypes.arrayOf(PropTypes.string),
  ticketNameChoisi : PropTypes.string,
  setTicketNameChoisi : PropTypes.func,
  idTicketsRepeter: PropTypes.arrayOf(PropTypes.string),
};

export default MiniStatisticsCard;