/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Fragment } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// react-router components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DefaultNavbarCategory from "examples/Navbars/DefaultNavbar/DefaultNavbarCategory";
import DefaultNavbarMenu from "examples/Navbars/DefaultNavbar/DefaultNavbarMenu";

function EcommerceMenu({ routes, open, close, mobileMenu }) {
  const renderEcommerceMenuRoute = (routeName) =>
    routes.map(
      ({ key, name, icon, collapse }) =>
        key === routeName && (
          <Fragment id='Fragment-EcommerceMenu' key={key}>
            <DefaultNavbarCategory id='DefaultNavbarCategory-EcommerceMenu'  icon={icon} title={name} />
            {collapse.map(({ key: collapseKey, route, name: collapseName }) => (
              <MenuItem
              id='MenuItem-EcommerceMenu' 
                key={collapseKey}
                component={Link}
                to={route}
                onClick={mobileMenu ? undefined : close}
              >
                <SoftBox id='SoftBox1-EcommerceMenu'  color="text" pl={2}>
                  {collapseName}
                </SoftBox>
              </MenuItem>
            ))}
          </Fragment>
        )
    );

  const renderMenuContent = (
    <SoftBox id='SoftBox2-EcommerceMenu'  py={1} px={2}>
      <Grid id='Grid1-EcommerceMenu' container spacing={3}>
        <Grid id='Grid2-EcommerceMenu'  item xs={12} lg={5}>
          {renderEcommerceMenuRoute("orders")}
          <SoftBox id='SoftBox3-EcommerceMenu'  mt={2}>{renderEcommerceMenuRoute("general")}</SoftBox>
        </Grid>
        <Grid id='Grid3-EcommerceMenu'  item xs={12} lg={7} sx={{ display: "flex" }}>
          <SoftBox id='SoftBox4-EcommerceMenu'  display={{ xs: "none", lg: "block" }}>
            <Divider id='Divider-EcommerceMenu'  orientation="vertical" />
          </SoftBox>
          <SoftBox id='SoftBox5-EcommerceMenu'  width="100%">{renderEcommerceMenuRoute("products")}</SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );

  return mobileMenu ? (
    renderMenuContent
  ) : (
    <DefaultNavbarMenu id='DefaultNavbarMenu-EcommerceMenu'  open={open} close={close}>
      {renderMenuContent}
    </DefaultNavbarMenu>
  );
}

// Setting default values for the props of EcommerceMenu
EcommerceMenu.defaultProps = {
  mobileMenu: false,
  open: false,
  close: false,
};

// Typechecking props for the EcommerceMenu
EcommerceMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  close: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  mobileMenu: PropTypes.bool,
};
export default EcommerceMenu;
