import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SoftBox from "components/SoftBox";
import LoadingBackdrop from "utils/LoadingBackdrop";
import useStatutForm from "./hooks/useStatutForm";

export default function AddStatut({ handleFetch }) {
  const {
    errorMessage,
    open,
    loading,
    inputs,
    handleClickOpen,
    handleClose,
    handleInputChange,
    handleAddInput,
    handleRemoveInput,
    handleAddStatut,
    handleKeyPress,
     setInputs
  } = useStatutForm(handleFetch);
  

  return (
    <div id="statuts-container-div">
    <LoadingBackdrop id="statuts-loading-backdrop" open={loading} />
    <SoftButton
      id="add-statut-button"
      onClick={handleClickOpen}
      variant="gradient"
      color="purple"
      size="small"
      mb={{ xs: 2, sm: 0 }}
    >
      +Ajouter Statut
    </SoftButton>
    <Dialog
      id="add-statut-dialog"
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      onKeyDown={handleKeyPress}
    >
      <DialogTitle id="add-statut-dialog-title">Ajouter Statut</DialogTitle>
      <IconButton
        id="close-dialog-button"
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
          "&:hover": {
            backgroundColor: (theme) => theme.palette.grey[200],
          },
        }}
      >
        <CloseIcon id="close-dialog-icon" />
      </IconButton>
      <DialogContent id="add-statut-dialog-content">
        <DialogContentText id="add-statut-dialog-content-text">
          Vous devez saisir le nom de statut  afin de l&apos;ajouter.
        </DialogContentText>
        {inputs.map((input, index) => (
          <Grid key={index} sx={{ mt: 1 }} id={`statut-input-grid-${index}`}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="medium"
              color="text"
              sx={{ mb: 0.5 }}
              id={`statut-input-label-${index}`}
            >
              Nom de statut*
            </SoftTypography>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-around",
              }}
              id={`statut-input-wrapper-${index}`}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
                id={`statut-input-column-${index}`}
              >
                <SoftInput
                  name="titre"
                  margin="normal"
                  id={`statut-input-${index}`}
                  label="statut"
                  type="text"
                  fullWidth
                  placeholder="Nom de statut"
                  inputProps={{
                    minLength: 2,
                    maxLength: 50,
                  }}
                  autoFocus
                  variant="standard"
                  value={input.titre}
                  onChange={(event) => handleInputChange(index, event)}
                  onBlur={() => {
                    const newInputs = [...inputs];
                    newInputs[index].error = input.titre.length < 2;
                    setInputs(newInputs);
                  }}
                  error={input.error && input.titre.trim().length < 2}
                  success={input.titre.trim().length >= 2}
                />
                {input.titre.trim().length < 2 && (
                  <Typography
                    m={0.75}
                    variant="caption"
                    color="error"
                    id={`statut-input-error-${index}`}
                  >
                    {errorMessage}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={0.5} id={`statut-remove-button-grid-${index}`}>
                {inputs.length > 1 && (
                  <IconButton
                    id={`remove-statut-button-${index}`}
                    onClick={() => handleRemoveInput(index)}
                  >
                    <RemoveCircleIcon
                      id={`remove-statut-icon-${index}`}
                      color="error"
                      fontSize="medium"
                    />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ height: "100%" }}
          id="statut-add-more-stack"
        >
          <SoftButton
            id="add-more-statut-button"
            size="small"
            sx={{
              mt: 2,
              borderWidth: "2px",
              borderStyle: "dashed solid",
              borderColor: "purple ",
            }}
            onClick={handleAddInput}
          >
            +ajouter plus
          </SoftButton>
        </Stack>
      </DialogContent>
      <DialogActions id="add-statut-dialog-actions">
        <SoftBox
          mt={2}
          width="100%"
          display="flex"
          justifyContent="space-between"
          id="dialog-actions-box"
        >
          <SoftButton
            id="cancel-add-statut-button"
            onClick={handleClose}
            sx={{ mt: 2 }}
            variant="outlined"
            color="dark"
            size="small"
          >
            Annuler
          </SoftButton>
          <SoftButton
            id="confirm-add-statut-button"
            onClick={handleAddStatut}
            sx={{ mt: 2 }}
            variant="gradient"
            color="purple"
            size="small"
          >
            Ajouter
          </SoftButton>
        </SoftBox>
      </DialogActions>
    </Dialog>
  </div>
  
  );
}

AddStatut.propTypes = {
  handleFetch: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
