import React from 'react';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingBackdrop from 'utils/LoadingBackdrop';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import PropTypes from 'prop-types';
import useStatutActions from '../hooks/useStatutActions';
import VisibilityIcon from '@mui/icons-material/Visibility';

function StatutActions({ statut, handleFetch}) {
  const {
    open,
    setOpen,
    titre,
    setName,
    loading,
    errorMessage,
    handleUpdate,
    handleClose,
    showAlert,
  } = useStatutActions(statut, handleFetch);


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleUpdate();
    }
  };

  return (
    <SoftBox display="flex" alignItems="center" id="action-buttons-box">
    <LoadingBackdrop id="edit-delete-loading-backdrop" open={loading} />
    
    <SoftBox mx={2} id="edit-button-box">
      <SoftTypography variant="body1" color="purple" sx={{ cursor: 'pointer', lineHeight: 0 }} id="edit-typography">
        <Tooltip title="Modifier" placement="top" id="edit-tooltip">
          <Icon onClick={() => setOpen(true)}    id={`edit-statut-actions-${statut._id}`}>
            edit
          </Icon>
        </Tooltip>
        <Dialog fullWidth id="modify-statut-dialog" open={open} onKeyDown={handleKeyPress}>
          <DialogTitle id="modify-statut-dialog-title">Modifier statut</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              '&:hover': {
                backgroundColor: (theme) => theme.palette.grey[200],
              },
            }}
            id="close-modify-statut-dialog-button"
          >
            <CloseIcon id="close-modify-statut-dialog-icon" />
          </IconButton>
          <DialogContent id="modify-statut-dialog-content">
            <DialogContentText id="modify-statut-dialog-content-text">
              Vous devez saisir le nouveau nom de votre statut.
            </DialogContentText>
            <Grid id="modify-statut-input-grid">
              <SoftTypography variant="caption" fontWeight="bold" color="text" id="modify-statut-label">
                Nom du statut*
              </SoftTypography>
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
                id="modify-statut-input-wrapper"
              >
                <SoftInput
                  name="titre"
                  margin="normal"
                  id="modify-statut-input"
                  label="statut"
                  type="text"
                  fullWidth
                  placeholder="Nom de statut"
                  inputProps={{
                    minLength: 2,
                    maxLength: 50,
                  }}
                  variant="standard"
                  value={titre}
                  onChange={(e) => setName(e.target.value)}
                  error={titre.trim().length < 2}
                  success={titre.trim().length >= 2}
                  autoFocus
                />
                {titre.trim().length < 2 && (
                  <SoftTypography m={0.75} variant="caption" color="error" id="modify-statut-error-message">
                    {errorMessage}
                  </SoftTypography>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions id="modify-statut-dialog-actions">
            <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between" id="modify-statut-buttons-box">
              <SoftButton
                onClick={handleClose}
                sx={{ mt: 2 }}
                size="small"
                variant="outlined"
                color="dark"
               
                id={`cancel-modify-statut-button-${statut._id}`}
              >
                Annuler
              </SoftButton>
              <SoftButton
                onClick={handleUpdate}
                sx={{ mt: 2 }}
                variant="gradient"
                color="customized"
                size="small"
               
                id={`confirm-modify-statut-button-${statut._id}`}
              >
                Modifier
              </SoftButton>
            </SoftBox>
          </DialogActions>
        </Dialog>
      </SoftTypography>
    </SoftBox>
    <SoftTypography variant="body1" color="purple" sx={{ cursor: 'pointer', lineHeight: 0 }} id="delete-typography">
      <Tooltip title="Supprimer" placement="left" id="delete-tooltip">
        <Icon id={`delete-statut-${statut._id}`} onClick={showAlert}>
          delete
        </Icon>
      </Tooltip>
    </SoftTypography>
  </SoftBox>
  
  );
}

StatutActions.propTypes = {
  statut: PropTypes.object.isRequired,
  handleFetch: PropTypes.func.isRequired,

};

export default StatutActions;
