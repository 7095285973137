import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import SoftSelect from "components/SoftSelect";
import { useEffect, useState } from "react";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import useReclamationParAgence from "../../hooks/useReclamationParAgence";
import SelectData from "../../data/SelectData";


function BarChart({title , verticalBarChartData , namesAgence , agenceNameChoisi , setAgenceNameChoisi, agenceID, setAgenceID, namesDepartement, departementNameChoisi, setDepartementNameChoisi, namesService, serviceNameChoisi, setServiceNameChoisi , choisirAnneeReclamation , setChoisirAnneeReclamation , choisirMoisReclamation, setChoisirMoisReclamation}){
  const [chartLabels, setChartLabels] = useState([]);

  useEffect(()=>{
    if (choisirMoisReclamation) {
      // Assuming choisirMoisReclamation is the selected month (1-12)
      // We need to set chart labels as days of the month
      const daysInMonth = new Date(choisirAnneeReclamation, choisirMoisReclamation, 0).getDate(); // Get total days in the selected month
      setChartLabels(Array.from({ length: daysInMonth }, (_, i) => i + 1)); // Create an array [1, 2, 3, ..., daysInMonth]
    } else if (choisirAnneeReclamation) {
      // If only the year is selected, show months
      setChartLabels(["Jan", "Fev", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]);
    }
  }, [choisirMoisReclamation, choisirAnneeReclamation])

  const [choisirType, setChoisirType] = useState(undefined);


  useEffect(()=>{
    if(choisirType === "Départment"){
      setServiceNameChoisi("");
    }else if(choisirType === "Service"){
      setDepartementNameChoisi("")
    }else{
      setDepartementNameChoisi("")
      setServiceNameChoisi("");
    }
  }, [choisirType]);

  return(
      <Card id='Cart'>
          <Box id='box' display={{ xs: "block"}} justifyContent="space-between" p="18px 18px 0px 18px">
            <Typography id='Typography1' variant="h6" color="initial">{title}</Typography>
              <Grid id='Grid1' container spacing={2}>
                <Grid id='Grid2' item xs={12} md={3}>
                  <SoftTypography id='SoftTypo1'  variant="body" color="text" sx={{ fontSize: "12px" }} >
                    Nom Agence
                  </SoftTypography>
                  <SoftSelect
                  id='select1'
                    placeholder="Choissiser un agence"
                    options={namesAgence}
                    size="small"
                    value={agenceNameChoisi ?{ label: agenceNameChoisi, value: agenceID }: null}
                    onChange={(e)=>{
                      setAgenceNameChoisi(e.label)
                      setAgenceID(e.value)
                    }}
                  />
                </Grid>
                {
                  (agenceNameChoisi) &&
                    <Grid id='grid3' item xs={12} md={3}>
                      <SoftTypography id="SoftTypo2" variant="body" color="text" sx={{ fontSize: "12px" }} >
                        Type
                      </SoftTypography>
                      <SoftSelect
                      id="select2"
                        placeholder="Choissiser un agence"
                        options={[
                          {value: "Default", label:"Default"},
                          ...SelectData.type
                        ]}
                        size="small"
                        value={choisirType ?{ label: choisirType, value: choisirType }: null}
                        onChange={(e)=>{
                          if(e.value !== "Default"){
                            setChoisirType(e.value);
                          }else{
                            setChoisirType("");
                          }
                        }}
                      />
                    </Grid>
                }
                {
                  choisirType === "Départment" &&
                    <Grid id='grid4' item xs={12} md={3}>
                      <SoftTypography  id ='SoftTypo3' variant="body" color="text" sx={{ fontSize: "12px" }} >
                        Nom Départment
                      </SoftTypography>
                      <SoftSelect
                      id='select3'
                        placeholder="Choissiser une départment"
                        options={namesDepartement.map((nameDep)=>{
                          return {
                            value: nameDep,
                            label: nameDep
                          }
                        })}
                        size="small"
                        value={departementNameChoisi ?{ label: departementNameChoisi, value: departementNameChoisi }: null}
                        onChange={(e)=>{
                          setDepartementNameChoisi(e.value)
                        }}
                      />
                    </Grid>
                }
                {
                  choisirType === "Service" &&
                    <Grid id='Grid5' item xs={12} md={3}>
                      <SoftTypography id='SoftTypo4'  variant="body" color="text" sx={{ fontSize: "12px" }} >
                        Nom Service
                      </SoftTypography>
                      <SoftSelect
                      id='select4'
                        placeholder="Choissiser un service"
                        options={namesService.map((nameSer)=>{
                          return {
                            value: nameSer,
                            label: nameSer
                          }
                        })}
                        size="small"
                        value={serviceNameChoisi ?{ label: serviceNameChoisi, value: serviceNameChoisi }: null}
                        onChange={(e)=>{
                          setServiceNameChoisi(e.value)
                        }}
                      />
                    </Grid>
                }
                {!agenceNameChoisi && !choisirType &&
                  <Grid id='Grid6' item xs={0} md={9} />
                }
                {agenceNameChoisi && !choisirType &&
                  <Grid id='Grid7' item xs={0} md={6} />
                }
                {
                  choisirType &&
                  <Grid id='Grid8' item xs={0} md={3} />
                }
                <Grid id='Grid9' item xs={12} md={3}>
                  <SoftTypography id='SoftTypo5'  variant="body" color="text" sx={{ fontSize: "12px" }} >
                    Année
                  </SoftTypography>
                  <SoftSelect
                  id='select5'
                    placeholder="Choissiser une année"
                    options={[
                      {value: "Default", label:"Default"},
                      ...SelectData.annee
                    ]}
                    size="small"
                    value={choisirAnneeReclamation ? { value: choisirAnneeReclamation, label: choisirAnneeReclamation} : null}
                    onChange={(e)=>{
                      if(e.value !== "Default"){
                        setChoisirAnneeReclamation(e.value)
                      }else{
                        setChoisirAnneeReclamation(undefined)
                      }
                    }}
                  />
                </Grid>
                <Grid id='Grid10' item xs={12} md={3}>
                  <SoftTypography id='SoftTypo6' variant="body" color="text" sx={{ fontSize: "12px" }} >
                    Mois
                  </SoftTypography>
                  <SoftSelect
                  id='select6'
                    placeholder="Choissiser une mois"
                    options={[
                      {value: "Default", label:"Default"},
                      ...SelectData.mois
                    ]}
                    size="small"
                    value={SelectData.mois.find((item) => item.value === choisirMoisReclamation) || null}
                    onChange={(e)=>{
                      if(e.value !== "Default"){
                        setChoisirMoisReclamation(e.value)
                      }else{
                        setChoisirMoisReclamation(undefined)
                      }
                    }}
                  />
                </Grid>
              </Grid>
          </Box>
          <VerticalBarChart id='chart' chart={{ ...verticalBarChartData, labels: chartLabels }} />
      </Card>
  )
}

BarChart.propTypes = {
  title: PropTypes.string,
  verticalBarChartData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.oneOf(["primary", "secondary", "success", "error", "warning", "info", "dark", "light"]),
      data: PropTypes.arrayOf(PropTypes.number),
    })).isRequired,
    totalData: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  namesAgence: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })),
  agenceNameChoisi: PropTypes.string,
  setAgenceNameChoisi: PropTypes.func.isRequired,
  agenceID: PropTypes.string,
  setAgenceID: PropTypes.func.isRequired,

  namesDepartement: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })),
  departementNameChoisi: PropTypes.string,
  setDepartementNameChoisi: PropTypes.func.isRequired,

  namesService: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })),
  serviceNameChoisi: PropTypes.string,
  setServiceNameChoisi: PropTypes.func.isRequired,

  choisirAnneeReclamation: PropTypes.string,
  setChoisirAnneeReclamation: PropTypes.func.isRequired,
  choisirMoisReclamation: PropTypes.string,
  setChoisirMoisReclamation: PropTypes.func.isRequired,
  }

export default BarChart;