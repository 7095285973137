
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import SoftBox from "components/SoftBox";
import {
  navbar,
  navbarContainer,
  navbarDesktopMenu,
  navbarIconButton,
  navbarMobileMenu,
  navbarRow,
} from "layouts/DashboardNavbar/styles";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";
import {
 useSoftUIController,
  setMiniSidenav,
} from "context";
import DefaultItem from "examples/Items/DefaultItem";
import { Button } from "@mui/material";
import { blue } from "@mui/material/colors";
function DashboardNavbar({ absolute, light, isMini }) {
  const [clicked, setClicked] = useState(false);
  const [clickedMenuProfile, setClickedMenuProfile] = useState(false);
  const handleClick = () => { setClicked(!clicked); };
  const handleClickMenuProfile = () => { setClickedMenuProfile(!clickedMenuProfile); };
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [pageScrolled, setPageScrolled] = useState(false); // État local pour suivre si la page a été 
  const [openMenuProfile, setOpenMenuProfile] = useState(false);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      const scrolled = window.scrollY > 50;

      // Mettez à jour l'état local pour activer miniSidenav si la page a été scrollée
      setPageScrolled(scrolled);
    }
    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);
    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();
    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => {
    // Logique pour gérer l'ouverture du menu
    setOpenMenu(event.currentTarget);
    // Mettez à jour l'état pour indiquer que le bouton a été cliqué
    setButtonClicked(!buttonClicked);
  };
  const handleCloseMenu = () => {
    setOpenMenu(false);
    setClicked(false);
  };
  const handleOpenMenuProfile = (event) => setOpenMenuProfile(event.currentTarget);
  const handleCloseMenuProfile = () => {
    setOpenMenuProfile(false);
  }
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const refreshToken =
        localStorage.getItem("refreshToken") || sessionStorage.getItem("refreshToken");
      if (!refreshToken) return navigate("/login");
      /* await api.delete("/logout", {
        headers: { Authorization: `Basic ${refreshToken}` }
      }) */
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("facility");
      localStorage.removeItem("licence");
      sessionStorage.clear();
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  // Render the notifications menu
  const renderMenu = () => {
    return (
      <Menu
      id="Menu1"
        anchorEl={openMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}
      >
      </Menu>
    );
  };

  // Render the profile menu
  const renderMenuProfile = () => (
    <Menu
    id='menu2'
      anchorEl={openMenuProfile}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenuProfile)}
      onClose={handleCloseMenuProfile}
      sx={{ mt: 2 }}
    >
      <DefaultItem
      id='Default1'
        color="secondary"
        icon="account_circle"
        title={controller?.user?.info?.user?.firstName}
        description={controller?.user?.info?.user?.role}
        sx={{ cursor: "pointer" }}
        onClick={handleCloseMenuProfile}
      />
      <DefaultItem
      id="Default2"
        color="secondary"
        icon="settings"
        title="Parametres"
        description=""
        sx={{ cursor: "pointer" }}
        onClick={() => navigate(`/users/${controller?.user?.info?.user?._id}`)} 
      />
      <DefaultItem
      id='Default3'
        color="secondary"
        icon="logout"
        title="Déconnecter"
        description=""
        sx={{ cursor: "pointer" }}
        onClick={handleLogout}
      />
    </Menu>
  );

  return (
    <AppBar
    id="appBar"
      position={absolute ? "absolute" : navbarType}
      color={light ? "transparent" : "white"} // Utilisez l'état local pour définir la couleur de l'AppBar
      sx={(theme) => navbar(theme, { transparentNavbar, light })}
    >
      <Toolbar id='Toolbar' sx={(theme) => navbarContainer(theme)}>
        <SoftBox id='SoftBox1' color={"inherit"} mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Icon id='icon' fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
            {miniSidenav ? "menu_open" : "menu"}
          </Icon>
        </SoftBox>
        {isMini ? null : (
          //cas responsive 
          <SoftBox id='SoftBox2' sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <Button sx={{ mr: "16px", color: "blue", border:0 , "&:hover":{ textDecoration: "underline", color: blue[900] }, "&:active": { color: `${blue[900]} !important` }, "&:focus": { color: `${blue[900]} !important` } }}
              onClick={()=>{
                navigate("/reclamation/ajouterTicket")
              }}
            >
              Création Ticket
            </Button> */}
            <SoftButton
            id='SoftButton1'
              variant="gradient"
              color="purple"
              size="small"
              sx={{ mr: "16px" }}
              onClick={()=>{
                navigate("/reclamation/ajouterTicket")
                localStorage.removeItem('agenceID');
              }}
            >
              Création Ticket
            </SoftButton>
            <SoftBox id='SoftBox3'>
              <IconButton
              id='iconButton'
                color={"inherit"}
                size="large"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon  id='icon' className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
            </SoftBox>
            <SoftBox id='SoftBox3' mr={1.6} color={light ? "white" : "inherit"}>
              <SoftButton
              id='SoftButton2'
                color={clickedMenuProfile ? "light" : "white"}
                circular
                iconOnly
                style={{ width: "45px", height: "45px" }}
                //size="large"
                sx={{ navbarIconButton, boxShadow: '4', }}
                aria-controls="notification-menu"
                aria-haspopup="true"
                //variant="gradient"
                onClick={(event) => {
                  handleOpenMenuProfile(event);
                  handleClickMenuProfile();
                }}
              >
                <SoftAvatar id="SoftAv"  src={controller?.user?.info?.user?.imageFilename ? `${process.env.REACT_APP_BASE_URL}uploads/users/${controller?.user?.info?.user?.imageFilename}` : "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"} alt="profile-image"
                  style={{ width: "40px", height: "40px" }} />
              </SoftButton>
              {renderMenu()}
              {renderMenuProfile()}
            </SoftBox>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  );
}
// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;

